import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
        <Seo title="404: Not Found" />
        <h1>Not Found</h1>
        <img src="https://media.giphy.com/media/gngO1gmBhS9na/giphy.gif" alt="Confused Travolta Gif" />
        <p>You just hit a route that doesn&#39;t exist{" "}
          <span role="img" aria-label="wave emoji">
            😢
          </span></p>
      </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
